import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;

  .image {
    height: 80px;
    width: 80px;
  }

  .title {
    font-weight: 600;
    color: #4d4d4d;
    font-size: 28px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 5%;
  }

  .svg {
    width: 72%;
  }

  input {
    width: 90%;
    border: none;
    border-radius: 50px;
    background: #f1f1f1;
    padding: 5%;
    margin: 1.5% 0;
  }
  /*
  button {
    width: 50%;
    height: 8%;
    border-radius: 50px;
    background: #5fe59e;
    border: none;
    color: #fff;
    font-size: 20px;
  } */

  .image-t {
    width: 100%;
    border-radius: 10px;
  }

  .sub-t {
    font-weight: 300;
    color: #4d4d4d;
    font-size: 14px;
    padding-right: 5px;
  }

  .sub-ta {
    font-weight: 600;
    color: #4d4d4d;
    font-size: 14px;
  }

  .btn {
    font-size: 10px;
  }
`;

export const Card = styled.div`
  max-width: 600px;
  padding: 5%;
  margin: 5% 5% 0 5%;
  background: #eef2fd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  margin-top: 10%;
  font-size: 12px !important;
  border-radius: 50px;
  background: ${props => (props.background ? '#EB6464' : '#5FE59E')};
  border: none;
  color: #fff;
  width: 100%;
  padding: 15px 0;
`;

export const Registration = styled.li`
  padding: 20px;
  margin: 10px;
  border-radius: 4px;
  background: #f5f5f5;

  opacity: ${props => (props.checkin ? 0.6 : 1)};

  strong {
    display: block;
    color: ${props => (props.available ? '#999' : '#000')};
    font-size: 20px;
    font-weight: normal;
  }

  span {
    display: block;
    margin-top: 3px;
    color: ${props => (props.available ? '#999' : '#000')};
  }
`;
