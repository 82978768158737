/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '../../services/api';

import logo from '../../assets/img/logo.png';

import { Container, Card, Button, Registration } from './styles';

export default function Home() {
  const [filter, setFilter] = useState('');
  const [select, setSelect] = useState('');
  const [options, setOptions] = useState([
    { name: 'Token' },
    { name: 'Email' },
  ]);

  const [event, setEvent] = useState({});
  const [desativado, setDesativado] = useState(false);
  const [registered, setRegistered] = useState();
  const [registrations, setRegistrations] = useState([]);
  const [full, setFull] = useState();
  const [token, setToken] = useState();

  const { id } = useParams();

  useEffect(() => {
    api.get(`/events/${id}/registrations`).then(res => {
      console.log(res.data.date);
      res.data.date = parseISO(res.data.date);
      res.data.date = format(res.data.date, "EEEE', 'dd'/'MM' às ' HH:mm'h'", {
        locale: pt,
      });

      setEvent(res.data);
      setRegistrations(res.data.registrations);
      setRegistered(res.data.registered);
      setFull(res.data.full);
      setToken(res.data.token);
    });

    const interval = setInterval(() => {
      api.get(`/events/${id}/registrations`).then(res => {
        console.log(res.data.date);
        res.data.date = parseISO(res.data.date);
        res.data.date = format(
          res.data.date,
          "EEEE', 'dd'/'MM' às ' HH:mm'h'",
          {
            locale: pt,
          }
        );

        setEvent(res.data);
        setRegistrations(res.data.registrations);
        setRegistered(res.data.registered);
        setFull(res.data.full);
        setToken(res.data.token);
      });
    }, 5000);
  }, []);

  function handleMeRegistra() {
    setDesativado(true);
    // console.log(user);
    if (registered) {
      api
        .delete(`/registrations/event/${event.id}`)
        .then(res => {
          console.log(res.data);

          setRegistered(false);
          setDesativado(false);

          setToken(null);
          toast.success('Desinscrição feita com sucesso.');
        })
        .catch(err => {
          setDesativado(false);
          toast.warning('Desinscrição feita com sucesso.');

          return console.log(err);
        });
    } else {
      api
        .post(`/registrations/event/${event.id}`)
        .then(res => {
          console.log(res.data);
          setRegistered(true);
          setDesativado(false);
          setToken(res.data.token);

          toast.success('Inscrição feita com sucesso.');
        })
        .catch(err => {
          console.log(err);
          setDesativado(false);
          toast.error('Inscrição mal sucedido');
        });
    }
  }

  const filtro = registrations.filter(registration =>
    select === 'Email'
      ? registration.user.email.toLowerCase().indexOf(filter.toLowerCase()) > -1
      : registration.token.toLowerCase().indexOf(filter.toLowerCase()) > -1
  );

  // .filter(
  //   ((establishment) => establishment.marketSegment.code.toLowerCase()
  //     .indexOf(select.toLowerCase()) > -1),
  // ).filter(
  //   ((establishment) => establishment.address.city
  //     .indexOf(city) > -1),
  // );

  return (
    <Container>
      <div className="header">
        <Link
          to="/home"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px',
          }}
        >
          <MdKeyboardArrowLeft color="#4d4d4d" size={30} />
        </Link>
      </div>
      <p className="title">Eventos</p>
      <Card>
        <p style={{ fontWeight: 500 }}>{event.title}</p>
        <p style={{ fontSize: 12 }}>{event.caption}</p>
        <p style={{ padding: '2% 0' }}>{event.date}</p>
      </Card>
      <div styles={{ display: 'flex', flexDirection: 'row' }}>
        <input
          placeholder="Buscar token ou nome."
          type="text"
          onChange={event => setFilter(event.target.value)}
          value={filter}
          id="search"
        />
        <select onChange={event => setSelect(event.target.value)}>
          {options.map(option => (
            <option key={option} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>

      <ul>
        {filtro &&
          filtro.map(registration => (
            <Link
              key={registration.token}
              to={`/evento/${id}/token/${registration.token}`}
            >
              <Registration checkin={registration.check_in}>
                <strong>Token: {registration.token}</strong>
                <span>Email: {registration.user.email}</span>
              </Registration>
            </Link>
          ))}
      </ul>
    </Container>
  );
}
