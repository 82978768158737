/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { MdExitToApp } from 'react-icons/md';
import history from '../../services/history';

import { useAuth } from '../../Context/AuthContext';

import api from '../../services/api';

import { Container, Card } from './styles';

export default function Home() {
  const [events, setEvents] = useState([]);
  const { setSigned } = useAuth();

  let interval;
  useEffect(() => {
    api.get('/events').then(res => {
      setEvents(res.data);
    });

    interval = setInterval(() => {
      api
        .get('/events')
        .then(res => {
          setEvents(res.data);
        })
        .catch(err => {
          clearInterval(interval);
        });
    }, 5000);
  }, []);

  function handleLogout() {
    localStorage.removeItem('@AdValentina1Operador:token');
    localStorage.removeItem('@AdValentina1Operador:user');
    api.defaults.headers.Authorization = ``;
    setSigned(false);
    history.push('/');
  }

  return (
    <Container>
      <div className="header">
        <button
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px',
            border: 'none',
            background: 'none',
          }}
          onClick={handleLogout}
        >
          <MdExitToApp color="#EB6464" size={30} />
        </button>
      </div>
      <p className="title">Eventos</p>
      {events.map(event => (
        <Card
          key={event.id}
          to={`/evento/${event.id}`}
          desativar={event.full || !event.check_in}
        >
          <p>{event.title}</p>
          <p>{event.caption}</p>
        </Card>
      ))}
    </Container>
  );
}
