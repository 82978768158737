/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { toast } from 'react-toastify';

import api from '../../services/api';

import logo from '../../assets/img/logo.png';

import { Container, Card, Button } from './styles';

export default function Home() {
  const [desativado, setDesativado] = useState(false);
  const [registration, setRegistration] = useState({});
  const [checkin, setCheckIn] = useState();

  const { id, token } = useParams();

  console.log(token);

  useEffect(() => {
    api.get(`/registrations/token/${token}`).then(res => {
      setRegistration(res.data);
      setCheckIn(res.data.check_in);
      console.log(res.data);
      console.log(res.data.user);
    });
  }, []);

  function handleMeRegistra() {
    setDesativado(true);
    // console.log(user);
    if (checkin) {
      toast.info('Token já validado.');
      setDesativado(false);
    } else {
      api
        .put(`/registrations/token/${token}`)
        .then(res => {
          console.log(res.data);
          setDesativado(false);
          setCheckIn(true);
          toast.success('Token validado com sucesso');
        })
        .catch(err => {
          console.log(err);
          setDesativado(false);
          toast.error('Algo está errado.');
        });
    }
  }

  return (
    <Container>
      <div className="header">
        <Link
          to={`/evento/${id}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px',
          }}
        >
          <MdKeyboardArrowLeft color="#4d4d4d" size={30} />
        </Link>
      </div>
      <p className="title">Eventos</p>

      {registration.event && (
        <Card>
          <p style={{ fontWeight: 500 }}>{registration.event.title}</p>
          <p style={{ fontSize: 11 }}>{registration.user.nome}</p>
          <p style={{ fontSize: 11 }}>{registration.user.email}</p>
          {token && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p className="sub-t">Código: </p>
              <p className="sub-ta">{token}</p>
            </div>
          )}
          <Button
            // background={!!(registered || full || !event.check_in)}
            background={checkin}
            onClick={handleMeRegistra}
            // disabled={!!(!event.check_in || desativado || (full && !registered))}
            disabled={checkin}
          >
            {desativado
              ? 'Validando...'
              : checkin
              ? 'Token já validado'
              : 'Validar'}
          </Button>
        </Card>
      )}
    </Container>
  );
}
