import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import Evento from '../pages/Evento';
import Event_Token from '../pages/Event_Token';

import notFound from '../pages/notFound';

import AuthProvider from '../Context/AuthContext';

export default function Routes() {
  return (
    <AuthProvider>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/home" exact isPrivate component={Home} />
        <Route path="/evento/:id" exact isPrivate component={Evento} />
        <Route
          path="/evento/:id/token/:token"
          exact
          isPrivate
          component={Event_Token}
        />

        <Route path="/" component={notFound} />
      </Switch>
    </AuthProvider>
  );
}
