import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  background: linear-gradient(-90deg, #f7f5f4, #ffffff);

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.7);
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;
