import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import api from '../services/api';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [signed, setSigned] = useState(false);
  const [,] = useState(() => {
    const token = localStorage.getItem('@AdValentina1Operador:token');

    if (token) {
      api.defaults.headers.Authorization = token;
      setSigned(true);
    }
  });

  return (
    <AuthContext.Provider value={{ signed, setSigned }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
